import { useCallback, useState } from 'react'

import type { PaginatedContract } from '@paniclobster/commons'

import { postReadNotificationByNotificationUUID } from '../../../../api'

import useSessionNotifications from '../../../../hooks/useSessionNotifications'

export interface UseReadNotificationFormOptions {
  notificationUUID: string
}

// TODO: Properly handle all errors
export default function useReadNotificationForm(
  options: UseReadNotificationFormOptions,
) {
  const { notificationUUID } = options

  const { setNotifications } = useSessionNotifications()

  const [isSubmittingReadNotification, setIsSubmittingReadNotification] =
    useState(false)

  const submitReadNotification = useCallback(async () => {
    setIsSubmittingReadNotification(true)

    if (notificationUUID) {
      const requestResponse = await postReadNotificationByNotificationUUID(
        notificationUUID,
      ).catch(() => null)

      setNotifications((notifications) => {
        if (notifications) {
          return {
            ...notifications,
            pages: notifications.pages.map((page) => ({
              pagination:
                page?.pagination as PaginatedContract<Notification>['pagination'],
              data:
                page?.data.map((notification) =>
                  requestResponse && notification.id === requestResponse?.id
                    ? requestResponse
                    : notification,
                ) ?? [],
            })),
          }
        }

        return notifications
      })

      return requestResponse
    }

    setIsSubmittingReadNotification(false)

    return null
  }, [setIsSubmittingReadNotification, setNotifications, notificationUUID])

  return {
    isSubmittingReadNotification,
    submitReadNotification,
  }
}
