import { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { NotificationStatuses, NotificationTypes } from '@paniclobster/commons'

import type { NotificationContract } from '@paniclobster/commons'

import {
  Badge,
  Dropdown,
  FontAwesomeIcon,
  IconButton,
} from '@paniclobster/komp'

import type { MenuProps } from '@paniclobster/komp'

import useSessionNotifications from '../../../hooks/useSessionNotifications'

import LoadingContent from '../../LoadingContent/LoadingContent'
import MessageContent from '../../MessageContent/MessageContent'

import NotificationIcon from './NotificationIcon/NotificationIcon'
import NotificationLabel from './NotificationLabel/NotificationLabel'

import styles from './NotificationsMenuContent.module.css'

function notificationURL(notification: NotificationContract) {
  switch (notification.type) {
    case NotificationTypes.WorkspacesTeamInviteReceived:
      if (!notification.data?.workspaceSlug) {
        return undefined
      }
      return `/w/${notification.data.workspaceSlug}/teams-invites`
    case NotificationTypes.WorkspacesTeamInviteAccepted:
    case NotificationTypes.WorkspacesTeamVacancyCandidacyApplicationAccepted:
    case NotificationTypes.WorkspacesTeamVacancyCandidacyApplicationReceived:
    case NotificationTypes.WorkspacesTeamVacancyCandidacyWithdrawalReceived:
      if (!notification.data?.workspaceSlug) {
        return undefined
      }
      return `/w/${notification.data.workspaceSlug}/team`
    default:
      return undefined
  }
}

export default function NotificationsMenuContent() {
  const { t: translateCommon } = useTranslation('common')

  const router = useRouter()

  const {
    fetchNextNotificationsPage,
    hasNextNotificationsPage,
    isFetchingNotificationsPage,
    notifications,
  } = useSessionNotifications({ suspense: true })

  const { inView: nextPageFetcherInView, ref: nextPageFetcherRef } = useInView()

  useEffect(() => {
    if (
      !isFetchingNotificationsPage &&
      hasNextNotificationsPage &&
      nextPageFetcherInView
    ) {
      fetchNextNotificationsPage()
    }
  }, [
    fetchNextNotificationsPage,
    hasNextNotificationsPage,
    isFetchingNotificationsPage,
    nextPageFetcherInView,
  ])

  const badgeCount = useMemo(
    () =>
      notifications?.filter(
        (notification) => notification.status !== NotificationStatuses.Read,
      ).length ?? 0,
    [notifications],
  )

  const menuItems = useMemo<Required<MenuProps>['items']>(() => {
    if (!notifications || notifications.length <= 0) {
      return [
        {
          key: 'notifications-next-page-fetcher',
          label: (
            <MessageContent
              title={translateCommon('notificationsMenu.noNotifications') ?? ''}
            />
          ),
          type: 'group',
        },
      ]
    }

    let notificationsMenuItems = notifications.reduce<
      Required<MenuProps>['items']
    >((accumulator, notification) => {
      if (!notification.status || !notification.type) {
        return accumulator
      }

      const onClickURL = notificationURL(notification)

      return [
        ...accumulator,
        {
          label: <NotificationLabel notification={notification} />,
          icon: <NotificationIcon notification={notification} />,
          key: notification.uuid as string,
          onClick: () => {
            if (onClickURL) {
              router.push(onClickURL)
            }
          },
        },
      ]
    }, [])

    if (hasNextNotificationsPage) {
      notificationsMenuItems = [
        ...notificationsMenuItems,
        {
          key: 'notifications-next-page-fetcher',
          label: <LoadingContent ref={nextPageFetcherRef} />,
          type: 'group',
        },
      ]
    }

    return notificationsMenuItems
  }, [
    hasNextNotificationsPage,
    nextPageFetcherRef,
    notifications,
    router,
    translateCommon,
  ])

  return (
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      menu={{ items: menuItems }}
      overlayClassName={styles.menu}
      trigger={['click']}
    >
      <div className={styles.toggler}>
        <Badge count={badgeCount} size="small">
          <IconButton
            icon={<FontAwesomeIcon name="bell" />}
            labelPlacement="left"
            size="small"
          >
            {translateCommon('notificationsMenu.notifications')}
          </IconButton>
        </Badge>
      </div>
    </Dropdown>
  )
}
