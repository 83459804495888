import Link from 'next/link'
import classNames from 'classnames'

import { Sticky, Surface, Title } from '@paniclobster/komp'

import useAuthenticationContext from '../../../hooks/useAuthenticationContext'

import InpersonateBox from '../../ImpersonateBox/ImpersonateBox'
import Logo from '../../Logo/Logo'

import AccountMenu from '../AccountMenu/AccountMenu'
import NotificationsMenu from '../NotificationsMenu/NotificationsMenu'

import styles from './ApplicationHeader.module.css'

export interface ApplicationHeaderProps {
  className?: string
  title?: string
}

export default function ApplicationHeader(props: ApplicationHeaderProps) {
  const { className, title = 'Panic Lobster' } = props

  const authentication = useAuthenticationContext()

  return (
    <Sticky className={styles.sticky} top={0}>
      <Surface
        className={classNames(className, styles.root)}
        elevation={3}
        fullWidth
      >
        <div className={styles.container}>
          <Surface className={styles.content} fullHeight fullWidth padded>
            <div className={styles.leftSideContent}>
              <Link href="/">
                <Logo className={styles.logo} />
              </Link>
            </div>

            <Title>{title}</Title>

            <div className={styles.rightSideContent}>
              <InpersonateBox />

              {!!authentication && <NotificationsMenu />}

              {!!authentication && <AccountMenu />}
            </div>
          </Surface>
        </div>
      </Surface>
    </Sticky>
  )
}
