import classNames from 'classnames'
import { useTranslation } from 'next-i18next'

import { Surface, Text, useTestIds } from '@paniclobster/komp'

import type { SurfaceProps, TextProps } from '@paniclobster/komp'

import styles from './MessageContent.module.css'

type Size = 'default' | 'large'

const TEXT_VARIANTS: Record<Size, TextProps['variant']> = {
  default: 'body',
  large: 'bodyLarge',
}

export interface MessageContentProps extends SurfaceProps {
  className?: string
  size?: 'default' | 'large'
  title?: string
  testId?: string
}

export default function MessageContent(props: MessageContentProps) {
  const { t: translateCommon } = useTranslation('common')

  const {
    className,
    size = 'default',
    title = translateCommon('text.notFound'),
    testId,
    ...surfaceProps
  } = props

  const testIds = useTestIds(testId, { text: 'text' })

  return (
    <Surface
      className={classNames(className, styles.root)}
      {...surfaceProps}
      testId={testIds.root}
    >
      <Text
        className={styles.title}
        variant={TEXT_VARIANTS[size]}
        testId={testIds.text}
      >
        {title}
      </Text>
    </Surface>
  )
}
