import axios from 'axios'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import type { UserContract } from '@paniclobster/commons'

import { SelectInput } from '@paniclobster/komp'

import type { SelectInputValue } from '@paniclobster/komp'

import { useQuery } from '@tanstack/react-query'

import { getApiUrl } from '../../../core/configs'

export default function UsersSelect() {
  const router = useRouter()

  const [userValue, setUserValue] = useState<SelectInputValue>()

  const users = useQuery<UserContract[]>(
    ['administration', 'users'],
    () =>
      axios
        .get(getApiUrl('/administration/users'))
        .then((response) => response?.data ?? null)
        .catch(() => null),
    { suspense: true },
  )

  useEffect(() => {
    if (userValue) {
      window.location.href = `${getApiUrl(
        '/administration/session/impersonate',
      )}?email=${userValue}&return-to-uri=${router.asPath}`
    }
  }, [userValue, router])

  return (
    <SelectInput
      fullWidth
      name="impersonate"
      onChange={setUserValue}
      options={
        users.data?.map((user) => ({
          label: user.email as string,
          value: user.email as string,
        })) ?? []
      }
      placeholder="Impersonate"
      value={userValue}
    />
  )
}
