import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationStatuses } from '@paniclobster/commons'

import type { NotificationContract } from '@paniclobster/commons'

import useReadNotificationForm from '../hooks/useReadNotificationForm'

export interface NotificationLabelProps {
  notification: NotificationContract
}

export default function NotificationLabel(props: NotificationLabelProps) {
  const { t: translateNotifications } = useTranslation('notifications')

  const { notification, ...otherProps } = props

  const { submitReadNotification } = useReadNotificationForm({
    notificationUUID: notification.uuid as string,
  })

  const label = translateNotifications(`${notification.type}.title`, {
    data: notification.data,
  })

  const labelElement = useMemo(
    () =>
      notification.status !== NotificationStatuses.Read ? (
        <b>{label}</b>
      ) : (
        label
      ),
    [label, notification],
  )

  const onReadNotification = useCallback(() => {
    if (notification.status !== NotificationStatuses.Read) {
      submitReadNotification()
    }
  }, [notification, submitReadNotification])

  return (
    <span
      {...otherProps}
      onFocus={onReadNotification}
      onMouseOver={onReadNotification}
    >
      {labelElement}
    </span>
  )
}
