import type { ReactNode } from 'react'

import { Sticky, Surface } from '@paniclobster/komp'

export interface ApplicationRightSidebarProps {
  children?: ReactNode
  className?: string
}

export default function ApplicationRightSidebar(
  props: ApplicationRightSidebarProps,
) {
  const { children, className } = props

  return (
    <Sticky top={64 + 8}>
      <Surface className={className} elevation={2} fullWidth padded>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius magni
        ipsam natus, aut magnam sint culpa laborum quia eaque repudiandae a
        voluptatum eum voluptatem minus tenetur maxime doloremque unde saepe!
      </Surface>

      {children}
    </Sticky>
  )
}
