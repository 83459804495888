import { useCallback, useMemo } from 'react'

import type { NotificationContract } from '@paniclobster/commons'

import { useInfiniteQuery } from '@tanstack/react-query'

import type {
  InfiniteData,
  QueryFunction,
  Updater,
} from '@tanstack/react-query'

import {
  queryClient,
  useQueryOptions,
  useQueryCallbackOptions,
} from '../helpers/query'

import type { BaseQueryOptions } from '../helpers/query'

import { getSessionNotifications } from '../api'

import type { GetSessionNotificationsResponse } from '../api'

import { DEFAULT_PER_PAGE } from '../config/pagination'

export function getSessionNotificationsQueryKey() {
  return ['session', 'notifications']
}

export function useSessionNotificationsQueryKey() {
  return useMemo(() => getSessionNotificationsQueryKey(), [])
}

export interface FetchSessionNotificationsPageOptions {
  page?: number
}

export type UseSessionNotificationsOptions = BaseQueryOptions

export default function useSessionNotifications(
  options: UseSessionNotificationsOptions = {},
) {
  const queryOptions = useQueryOptions(options)

  const queryCallbackOptions = useQueryCallbackOptions(options)

  const queryKey = useSessionNotificationsQueryKey()

  const queryCallback: QueryFunction<GetSessionNotificationsResponse> =
    useCallback(
      async ({ signal, pageParam = 0 }) => {
        const requestParams = {
          page: pageParam,
          perPage: DEFAULT_PER_PAGE,
        }

        const requestResponse = await getSessionNotifications(requestParams, {
          abortSignal: signal,
          ...queryCallbackOptions,
        })

        return requestResponse
      },
      [queryCallbackOptions],
    )

  const query = useInfiniteQuery(queryKey, queryCallback, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.pagination.hasNextPage) {
        return lastPage.pagination.page + 1
      }

      return undefined
    },
    keepPreviousData: true,
    ...queryOptions,
  })

  const fetchQueryPage = useCallback(
    async (
      fetchQueryPageOptions: FetchSessionNotificationsPageOptions = {},
    ) => {
      const { page = 0 } = fetchQueryPageOptions

      const response = await query.refetch({
        refetchPage: (_, index) => index === page,
      })

      return response.data?.pages[page]?.data ?? null
    },
    [query],
  )

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries([queryKey])
  }, [queryKey])

  const queryData = useMemo(
    () =>
      query.data?.pages.reduce<NotificationContract[]>((accumulator, page) => {
        if (!page?.data) {
          return accumulator
        }

        return [...accumulator, ...page.data]
      }, []),
    [query],
  )

  const setQueryData = useCallback(
    (
      updater: Updater<
        InfiniteData<GetSessionNotificationsResponse> | undefined,
        InfiniteData<GetSessionNotificationsResponse> | undefined
      >,
    ) => {
      queryClient.setQueryData(queryKey, updater)
    },
    [queryKey],
  )

  return {
    fetchNextNotificationsPage: query.fetchNextPage,
    fetchNotificationsPage: fetchQueryPage,
    hasNextNotificationsPage: query.hasNextPage ?? false,
    invalidateNotifications: invalidateQuery,
    isFetchingNotificationsPage: query.isFetching,
    notifications: queryData ?? null,
    setNotifications: setQueryData,
  }
}
