import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import type { StorageFileContract } from '@paniclobster/commons'

import { Avatar, Dropdown, FontAwesomeIcon, Tooltip } from '@paniclobster/komp'

import type { MenuProps } from '@paniclobster/komp'

import useSessionProfile from '../../../hooks/useSessionProfile'

import styles from './AccountMenu.module.css'

export default function AccountMenu() {
  const { t: translateCommon } = useTranslation('common')

  const { sessionProfile } = useSessionProfile()

  const profilePictureUri = sessionProfile?.picture
    ? (sessionProfile.picture as StorageFileContract).fileUri
    : undefined
  const profileNameInitial = sessionProfile?.name?.[0]?.toUpperCase() ?? (
    <FontAwesomeIcon name="user" />
  )

  const router = useRouter()
  const menuItems = useMemo<MenuProps['items']>(
    () => [
      {
        label: translateCommon('accountMenu.profile'),
        icon: <FontAwesomeIcon name="face-smile" />,
        key: 'profile',
        onClick: () => router.push('/me/profile'),
      },
      { type: 'divider' },
      {
        label: translateCommon('accountMenu.signOut'),
        icon: <FontAwesomeIcon name="arrow-right-from-bracket" />,
        key: 'signOut',
        onClick: () => router.push('/signout'),
      },
    ],
    [router, translateCommon],
  )

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
      <div className={styles.toggler} role="button">
        <Tooltip
          placement="left"
          title={translateCommon('accountMenu.account')}
        >
          <Avatar
            src={profilePictureUri}
            className={styles.togglerIcon}
            size={40}
          >
            {profileNameInitial}
          </Avatar>
        </Tooltip>
      </div>
    </Dropdown>
  )
}
