import { Suspense } from 'react'

import LoadingContent from '../../LoadingContent/LoadingContent'

import NotificationsMenuContent from './NotificationsMenuContent'

export default function NotificationsMenu() {
  return (
    <Suspense fallback={<LoadingContent title="" variant="horizontal" />}>
      <NotificationsMenuContent />
    </Suspense>
  )
}
