import type { ReactNode } from 'react'

import { GridRow, GridCol } from '@paniclobster/komp'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'

import ApplicationContent from './ApplicationContent/ApplicationContent'
import ApplicationHeader from './ApplicationHeader/ApplicationHeader'
import ApplicationLeftSidebar from './ApplicationLeftSidebar/ApplicationLeftSidebar'
import ApplicationRightSidebar from './ApplicationRightSidebar/ApplicationRightSidebar'

import styles from './ApplicationLayout.module.css'

export interface ApplicationLayoutProps {
  children: ReactNode
  classes?: {
    content?: string
    header?: string
    leftSidebar?: string
    rightSidebar?: string
  }
  leftSidebar?: ReactNode
  rightSidebar?: ReactNode
  title?: string
}

export default function ApplicationLayout(props: ApplicationLayoutProps) {
  const { children, classes, leftSidebar, rightSidebar, title } = props

  const authentication = useAuthenticationContext()

  return (
    <div className={styles.root}>
      <ApplicationHeader className={classes?.header} title={title} />

      <div className={styles.container}>
        <GridRow gutter={[8, 8]}>
          <GridCol span={4}>
            {!!authentication && (
              <ApplicationLeftSidebar>{leftSidebar}</ApplicationLeftSidebar>
            )}
          </GridCol>

          <GridCol span={16}>
            <ApplicationContent className={classes?.content}>
              {children}
            </ApplicationContent>
          </GridCol>

          <GridCol span={4}>
            {!!authentication && (
              <ApplicationRightSidebar>{rightSidebar}</ApplicationRightSidebar>
            )}
          </GridCol>
        </GridRow>
      </div>
    </div>
  )
}
