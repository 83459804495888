import { useCallback, useMemo } from 'react'

import type { ProfileContract } from '@paniclobster/commons'

import { useQuery } from '@tanstack/react-query'

import type { QueryFunction } from '@tanstack/react-query'

import {
  queryClient,
  useQueryOptions,
  useQueryCallbackOptions,
} from '../helpers/query'

import type { BaseQueryOptions } from '../helpers/query'

import { getSessionProfile } from '../api'

import type { GetSessionProfileResponse } from '../api'

export function getSessionProfileQueryKey() {
  return ['session', 'profile']
}

export function useSessionProfileQueryKey() {
  return useMemo(() => getSessionProfileQueryKey(), [])
}

export type UseSessionProfileOptions = BaseQueryOptions

export default function useSessionProfile(
  options: UseSessionProfileOptions = {},
) {
  const queryOptions = useQueryOptions(options, {
    refetchInterval: 5 * 1000, // 5 seconds
  })

  const queryCallbackOptions = useQueryCallbackOptions(options)

  const sessionProfileQueryKey = useSessionProfileQueryKey()

  const sessionProfileQueryCallback: QueryFunction<GetSessionProfileResponse> =
    useCallback(
      async ({ signal }) => {
        const requestResponse = await getSessionProfile({
          abortSignal: signal,
          ...queryCallbackOptions,
        })

        return requestResponse
      },
      [queryCallbackOptions],
    )

  const sessionProfileQuery = useQuery(
    sessionProfileQueryKey,
    sessionProfileQueryCallback,
    { ...queryOptions },
  )

  const fetchSessionProfile = useCallback(
    async () => (await sessionProfileQuery.refetch()).data ?? null,
    [sessionProfileQuery],
  )

  const setSessionProfile = useCallback(
    (sessionProfile: ProfileContract | null) => {
      queryClient.setQueryData(sessionProfileQueryKey, () => sessionProfile)
    },
    [sessionProfileQueryKey],
  )

  return {
    fetchSessionProfile,
    isFetchingSessionProfile: sessionProfileQuery.isLoading,
    sessionProfile: sessionProfileQuery.data ?? null,
    setSessionProfile,
  }
}
