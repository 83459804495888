import classNames from 'classnames'

import type { ReactNode } from 'react'

import styles from './ApplicationContent.module.css'

export interface ApplicationContentProps {
  className?: string
  children: ReactNode
}

export default function ApplicationContent(props: ApplicationContentProps) {
  const { children, className } = props

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
