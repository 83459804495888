import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import type { ReactNode } from 'react'

import {
  Button,
  FontAwesomeIcon,
  GridCol,
  GridRow,
  Sticky,
  Surface,
} from '@paniclobster/komp'

import styles from './ApplicationLeftSidebar.module.css'

export interface ApplicationLeftSidebarProps {
  children?: ReactNode
  className?: string
}

export default function ApplicationLeftSidebar(
  props: ApplicationLeftSidebarProps,
) {
  const { t: translateCommon } = useTranslation('common')

  const { children, className } = props

  return (
    <Sticky top={64 + 8}>
      <GridRow gutter={[8, 8]}>
        <GridCol span={24}>
          <Surface className={className} elevation={2} fullWidth>
            <Link href="/workspaces/new" passHref legacyBehavior>
              <Button
                className={styles.menuItem}
                color="secondary"
                icon={
                  <FontAwesomeIcon
                    className={styles.menuItemIcon}
                    name="plus"
                  />
                }
                fullWidth
              >
                {translateCommon('navigationMenu.newWorkspace')}
              </Button>
            </Link>
          </Surface>
        </GridCol>

        <GridCol span={24}>
          <Surface className={className} elevation={2} fullWidth>
            <Link href="/" passHref legacyBehavior>
              <Button
                className={styles.menuItem}
                icon={
                  <FontAwesomeIcon
                    className={styles.menuItemIcon}
                    name="home"
                  />
                }
                fullWidth
                variant="text"
              >
                {translateCommon('navigationMenu.home')}
              </Button>
            </Link>
          </Surface>
        </GridCol>

        {children}
      </GridRow>
    </Sticky>
  )
}
