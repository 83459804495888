import classNames from 'classnames'
import { forwardRef } from 'react'
import { useTranslation } from 'next-i18next'

import type { ForwardedRef } from 'react'

import { FontAwesomeIcon, Text } from '@paniclobster/komp'

import type { FontAwesomeIconProps, TextProps } from '@paniclobster/komp'

import styles from './LoadingContent.module.css'

type Size = 'default' | 'large'

const ICON_SIZES: Record<Size, FontAwesomeIconProps['size']> = {
  default: 24,
  large: 32,
}

const TEXT_VARIANTS: Record<Size, TextProps['variant']> = {
  default: 'body',
  large: 'bodyLarge',
}

export interface LoadingContentProps {
  className?: string
  size?: Size
  title?: string
  variant?: 'horizontal' | 'vertical'
}

function LoadingContent(
  props: LoadingContentProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { t: translateCommon } = useTranslation('common')

  const {
    className,
    size = 'default',
    title = translateCommon('text.loading'),
    variant = 'vertical',
  } = props

  return (
    <div
      ref={ref}
      className={classNames(className, styles.root, {
        [styles.horizontal]: variant === 'horizontal',
        [styles.vertical]: variant === 'vertical',
        [styles.large]: size === 'large',
      })}
    >
      <FontAwesomeIcon
        className={styles.indicator}
        name="spinner"
        spin
        size={ICON_SIZES[size]}
      />

      <Text className={styles.title} variant={TEXT_VARIANTS[size]}>
        {title}
      </Text>
    </div>
  )
}

export default forwardRef<HTMLDivElement, LoadingContentProps>(LoadingContent)
