import { NotificationTypes } from '@paniclobster/commons'

import type { NotificationContract } from '@paniclobster/commons'

import { FontAwesomeIcon } from '@paniclobster/komp'

export interface NotificationIconProps {
  notification: NotificationContract
}

export default function NotificationIcon(props: NotificationIconProps) {
  const { notification, ...otherProps } = props

  switch (notification.type) {
    case NotificationTypes.WorkspacesTeamInviteReceived:
      return <FontAwesomeIcon name="envelope" {...otherProps} />
    default:
      return <FontAwesomeIcon name="bell" {...otherProps} />
  }
}
